:root {
  --primary-color: #c3098e;
  --primary-color-rgb: 195, 9, 142;
  --primary-color-light: #F6E4F1;
  --secondary-color: #702d94;
  --secondary-color-rgb: 112, 45, 148;
  --heading-color: #123452;
  --text-color: #4d4d4d;
  --light-color: #ffffff;
  --main-font: 'Work Sans', sans-serif;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--text-color);
  font-family: var(--main-font);
  font-size: 14px;
  &:not(.home) {
    .navbar-toggler {
      background-color: var(--primary-color-light);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: 0px 0px 0 5px rgba(250, 250, 250, .2);
      transition: all .3s ease-in-out;
      &:hover {
        box-shadow: 0px 0px 0 0px rgba(250, 250, 250, .2);
      }
    }
  }
  &.content-is-visible {
    height: 100vh;
    overflow-y: hidden;
  }
  @media (max-width: 450px) {
    overflow-x: hidden;
  }
}

a {
  transition: all .3s ease-in-out;
  &:hover {
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

input, 
select, 
textarea,
button {
  &:focus {
    outline: none;
  }
}

.global-container {
  background-color: #fff;
  z-index: 3;
}

@media only screen and (max-width: 991px) {
  // .global-container, .nm-header, .nm-footer {
  //   -webkit-transform: translateZ(0);
  //   -moz-transform: translateZ(0);
  //   -ms-transform: translateZ(0);
  //   -o-transform: translateZ(0);
  //   transform: translateZ(0);
  //   will-change: transform;
  // }
  
  .global-container, .nm-header, .nm-footer {
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }
  .global-container.nav-is-visible, .nm-header.nav-is-visible, .nm-footer.nav-is-visible {
    -webkit-transform: translateX(-260px);
    -moz-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -o-transform: translateX(-260px);
    transform: translateX(-260px);
  }
}

.nm-overlay {
  position: fixed;
  z-index: 2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: rgba(var(--secondary-color-rgb), 0.8);
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
}
.nm-overlay.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s, -webkit-transform 0.3s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s, -moz-transform 0.3s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.3s 0s;
}

.inline-link {
  font-weight: 600;
  color: var(--secondary-color);
  &:hover {
    color: var(--primary-color);
  }
}

.section {
  padding: 80px 0;
  &.light-bg { background-color: #F9F9F9; }
  &-title {
    h3 {
      color: var(--heading-color);
      font-size: 30px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  &-description {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    p {
      width: 1000%;
      @media (min-width: 576px) {
        width: 50%;
      }
    }
  }
  &.solicitudes {
    padding: 70px 0 150px;
  }
}

.btn-gradient {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  border: none;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 1.1em;
  padding: 1em;
  position: relative;
  transition: all .3s ease-in-out;
  &:hover {
    box-shadow: 0 10px 20px -10px var(--secondary-color);
    color: #fff;
  }
}

.btn-gradient.login-active {
  background: transparent;
  color: var(--primary-color);
  font-weight: 500;
  &::before {
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    content: '\f007';
    color: var(--secondary-color);
    font-family: 'Font Awesome 5 Free';
    background-color: var( --primary-color-light);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &::after {
    content: url('../../images/arrow_down.svg');
    position: absolute;
    top: 50%;
    right: -5px;
    line-height: 0;
    transform: translateY(-50%);
  }
  &:hover {
    box-shadow: unset;
  }
}

/** SWIPER SLIDER */
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/** SECTION TITLE */
.single-section__title {
  background-color: var(--primary-color-light);
  padding: 50px 0;
  position: relative;
  .before-title {
    color: var(--heading-color);
    display: flex;
    align-items: center;
    font-size: 1.1em;
    position: relative;
    &:after {
      content: '';
      background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
      display: inline-block;
      width: 100px;
      height: 2px;
      border-radius: 30px;
      margin-left: 10px;
    }
  }
  .title {
    color: var(--heading-color);
    font-weight: 700;
    @media (max-width: 1199px) {
      font-size: 2em;
    }
  }
  .image-wrapper {
    position: relative;
    img {
      position: absolute;
      top: -50px;
      right: 0;
      width: 400px;
    }
  }
}

/** SECTION CONTENT */
.text-content {
  @media (min-width: 768px) {
    padding: 30px;
  }
  p {
    font-size: 1.1em;
    &:not(:last-of-type) {
      margin-bottom: 1.1em;
    }
  }
}
.image-content {
  margin-top: 70px;
}

/** MAP */
#map {
  border-radius: 5px;
  height: 350px;
}

/** FORGOT PASSWORD LINK */
.forgot-password {
  color: var(--primary-color);
  &:hover {
    color: var(--secondary-color);
  }
}

/** WHATSAPP BTN */
.whatsapp-btn {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  color: var(--light-color);
  font-size: 30px;
  position: fixed;
  bottom: 20px;
  right: 15px;
  transition: all .3s ease-in-out;
  &:hover {
    box-shadow: 0 10px 20px -10px var(--secondary-color);
    color: #fff;
  }
}