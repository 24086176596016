/** TESTIMONIOS */
.testimonios {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  &-title h3 {
    color: var(--light-color);
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .swiper-slide-active {
    .testimonio-avatar {
      img {
        opacity: 1;
        transition-delay: .3s;
      }
    }
    .testimonio-caption {
      p {
        opacity: 1;
        transform: none;
        transition-delay: .3s;
      }
    }
    .testimonio-title {
      span {
        opacity: 1;
        transform: none;
        transition-delay: .5s;
      }
    }
  }
}

.testimonio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
  @media (min-width: 992px) {
    align-items: flex-end;
  }

  &-avatar {
    height: 100px;
    width: 110px;
    position: relative;
    margin-left: 5px;
    &:before {
      content: url('../../images/avatar-before.svg');
      position: absolute;
      top: -20px;
      left: 4px;
      transform: rotate(-60deg);
    }
    img {
      clip-path: url(#avatar-cut);
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: all .3s;
    }
  }

  &-caption {
    width: 100%;
    @media (min-width: 992px) {
      width: 75%;
    }
    p {
      color: var(--light-color);
      font-size: .9em;
      font-weight: 300;
      font-style: italic;
      margin-bottom: 0;
      text-align: center;
      opacity: 0;
      transform: translateY(25px);
      transition: all .7s;
      @media (min-width: 992px) {
        text-align: right;
      }
    }
  }
  
  &-title {
    span {
      color: var(--light-color);
      display: block;
      font-style: italic;
      opacity: 0;
      text-align: center;
      transform: translateY(25px);
      transition: all .7s;
      @media (min-width: 992px) {
        text-align: right;
      }
    }
  }
}

/** BOX */
.box {
  background-color: var(--light-color);
  border-radius: 5px;
  padding: 20px 20px 40px;
  position: relative;
  text-align: center;
  &-icon {
    margin-bottom: 30px;
    img {
      width: 100px;
    }
  }
  &.about {
    margin-top: 80px;
    padding-top: 100px;
    .box-icon {
      position: absolute;
      top: -75px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: auto;
      }
    } 
  }
}

.box-content {
  &__title {
    color: var(--secondary-color);
    font-weight: 600;
  }
  ul {
    margin: 20px 0 0;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      &:not(:last-of-type) {
        margin-bottom: 1em;
      }
      img {
        margin-right: 1em;
        width: 20px;
      }
      a {
        color: var(--secondary-color);
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}

.box-btn {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  .btn-gradient {
    padding: .8em 2em;
  }
}

/** SERVICES */
.services-item {
  &__image {
    margin-bottom: 1em;
  }
  &__content {
    .title {
      color: var(--secondary-color);
      margin-bottom: .5em;
    }
    .value {
      color: var(--primary-color);
      font-size: 1.2em;
      font-weight: 600;
    }
  }
}

.services-info {
  margin-top: 5em;
  position: relative;
  p {
    color: var(--heading-color);
    font-size: 1.2em;
    font-style: italic;
    font-weight: 600;
    margin-bottom: 0;
    text-align: center;
  }
}

/** FORMS */
.solicitud,
.registro,
.contact {
  .form-group {
    position: relative;
  }
  input, select, textarea {
    border: 2px solid var(--primary-color-light);
    border-radius: 5px;
    padding: 10px 10px;
    width: 100%;
    &:focus,
    &:valid {
      & + label span {
        background-color: var(--light-color);
        transform: translateY(-33px) !important;
      }
    }
    &[type="checkbox"] {
      background: url('../../images/hide_icon.svg') repeat no-repeat center center;
      border: none;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: auto;
      appearance: none;
      cursor: pointer;
      &:checked {
        background: url('../../images/show_icon.svg') repeat no-repeat center center;
      }
    }
  }
  textarea {
    & + label span {
      top: 10px;
      transform: unset;
    }
    &:focus,
    &:valid {
      & + label span {
        transform: translateY(-20px) !important;
      }
    }
  }
  label {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    span {
      color: var(--secondary-color);
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      transition: all .3s ease-in-out;
    }
  }
  #email[readonly="readonly"] {
    background-color: #eaeaea;
    & + label span {
      transform: translateY(-33px) !important;
    }
    &:focus,
    &:valid {
      & + label span {
        background-color: transparent;
      }
    }
  }
}

/** CONTACT INFO */
.contact-info {
  &__title {
    color: var(--secondary-color);
    font-size: 1.2em;
    font-weight: 600;
    margin-top: 1.4em;
    margin-bottom: .5em;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      color: var(--secondary-color);
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

/** SWEETALERT */
.nm-alert-container .swal2-popup.swal2-toast {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  box-shadow: 0 10px 8px -10px var(--secondary-color);
}
.nm-alert-container .swal2-title {
  font-weight: 500;
  color: var(--light-color);
}

/** TABS */
.account-tabs .nav-item {
  &:not(:last-of-type) {
    margin-right: 10px;
  }
}
.account-tabs .account-link {
  background-color: var(--primary-color-light);
  color: var(--secondary-color);
  font-size: 1.1em;
  @media (min-width: 350px) {
    font-size: 1.2em;
  }
  &:hover {
    background-color: darken(#F6E4F1, 5%);
  }
  &.active {
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
    color: var(--light-color);
  }
}

/** PLANES */
.plan-item {
  background-color: var(--primary-color-light);
  border-radius: 5px;
  padding: 20px;
  &.cancelado {
    background-color: #f8f8f8;
  }
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  &__title {
    color: var(--secondary-color);
    font-weight: 500;
    font-size: 1.4em;
    border-bottom: 1px solid #eec9e4;
    padding-bottom: .5em;
  }
}
.plan-item__vigencia {
  .desde, .hasta {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    @media (min-width: 350px) {
      flex-direction: row;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      font-weight: 500;
      color: var(--secondary-color);
      @media (min-width: 350px) {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
    span {
      color: var(--primary-color);
      font-weight: 600;
    }
  }
}
.plan-item__estado {
  p {
    font-weight: 500;
    color: var(--secondary-color);
  }
  span {
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
    border-radius: 5px;
    color: var(--light-color);
    padding: 5px 10px;
    font-weight: 600;
  }
}