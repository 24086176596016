/** HEADER */
.nm-header {
  z-index: 3;
  @media (max-width: 991px) {
    &.sticky {
      position: fixed !important;
      top: 0;
      width: 100%;
      z-index: 10;
      background: linear-gradient(90deg,var(--primary-color),var(--secondary-color));
      padding-bottom: 0 !important;
      &:after {
        display: none;
      }
    }
  }
}

.quienes_somos,
.servicios,
.solicitar-asesora,
.solicitar-trabajo,
.iniciar-sesion,
.registro,
.contacto,
.terminos,
.mi-cuenta,
.olvido-contrasena,
.restaura-contrasena {

  .nm-header {
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
    padding-bottom: 40px;
    position: relative;
    @media (min-width: 992px) {
      padding-bottom: 100px;
    }
    &:after {
      content: url('../../images/title-wave.svg');
      position: absolute;
      bottom: -6px;
      left: 0;
      width: 100%;
      z-index: 0;
    }
    .top-info .info a,
    .navbar-nav .nav-item .nav-link { 
      color: var(--light-color); 
    }
    .navbar-nav .nav-item .nav-link:after,
    .btn-gradient:not(.login):not(.login-active) { 
      background: var(--light-color);
      color: var(--secondary-color); 
    }
    .btn-gradient.login-active {
      color: var(--light-color);
      &::after {
        content: url('../../images/arrow_down_light.svg');
      }
    }
    .sociales {
      a {
        background-color: var(--primary-color-light);
        color: var(--secondary-color);
        &:hover {
          background-color: var(--primary-color) !important;
          color: var(--light-color);
        }
      }
    }
    .nav-trigger {
      span, 
      span::before, 
      span::after {
        background-color: var(--primary-color-light);
      }
      &.nav-is-visible {
        span {
          background-color: transparent !important;
        }
      }
    }
    .dropdown-link {
      &:after {
        content: url('../../images/arrow_down_light.svg');
      }
    }
  }

}

/** Top Header */
.home .top-header-wrapper {
  @media (max-width: 991px) {
    background-color: var(--primary-color-light);
  }
}
.top-header {
  padding: 20px 0;

  &__logo img {
    max-width: 100%;
    width: 200px;
    @media (min-width: 767px) {
      width: 250px;
    }
  }

}

.top-header__info {
  display: flex;
  align-items: center;
}

.top-info {
  display: flex;
  .info {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      margin-right: 45px;
    }
    img {
      margin-right: 1em;
      width: 20px;
    }
    a {
      color: var(--text-color);
      &:not(:last-of-type) {
        margin-right: 1em;
      }
      &:hover {
        color: var(--primary-color);
      }
    }
  }

}

.sociales {
  margin-left: 45px;
  @media (max-width: 991px) {
    &:not(.bottom) {
      opacity: 0;
    }
  }
  ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    li {
      &:not(:last-of-type) {
        margin-right: 1em;
      }
      a {
        background-color: var(--primary-color-light);
        color: var(--secondary-color);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        &:hover {
          background-color: var(--secondary-color) !important;
          color: var(--primary-color-light);
        }
      }
    }
  }
}

.nav-trigger {
  position: relative;
  display: block;
  width: 45px;
  height: 45px;
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
  z-index: 3;
  @media (min-width: 992px) {
    display: none;
  }
  span, 
  span::before, 
  span::after {
		/* hamburger icon in CSS */
		position: absolute;
		display: inline-block;
		height: 2px;
		width: 25px;
		background-color: var(--secondary-color);
  }
  span {
		/* line in the center */
		position: absolute;
		top: 50%;
		right: 10px;
		margin-top: -2px;
    transition: background 0.3s 0.3s;
    &::before,
    &::after {
      content: '';
      right: 0;
      /* Force Hardware Acceleration in WebKit */
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -o-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transform-origin: 0% 50%;
      -moz-transform-origin: 0% 50%;
      -ms-transform-origin: 0% 50%;
      -o-transform-origin: 0% 50%;
      transform-origin: 0% 50%;
      -webkit-transition: -webkit-transform 0.3s 0.3s;
      -moz-transition: -moz-transform 0.3s 0.3s;
      transition: transform 0.3s 0.3s;
    }
    &::before {
      top: -6px;
    }
    &::after {
      top: 6px;
    }
  }
  &.nav-is-visible {
    span {
      background-color: transparent;
      &:before {
        -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
        transform: translateX(4px) translateY(-3px) rotate(45deg);
      }
      &:after {
        -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
        transform: translateX(4px) translateY(2px) rotate(-45deg);
      }
    }
  }
}

/** Navigation */
.home .nav-wrapper {
  @media (max-width: 991px) {
    background: var(--primary-color-light) !important;
  }
}
.nav-wrapper {
  position: relative;
  z-index: 1;
  @media (max-width: 991px) {
    display: none;
  }
  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    transition: all .3s ease-in-out;
    z-index: 10;
    background: linear-gradient(90deg,var(--primary-color),var(--secondary-color));
    @media (min-width: 992px) {
      background-color: var(--primary-color);
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          @media (min-width: 992px) {
            color: var(--light-color);
            &:after {
              background-color: var(--light-color);
            }
          }
        }
      }
    }
    @media (min-width: 992px) {
      .btn-gradient:not(.login):not(.login-active) {
        background: var(--light-color) !important;
        color: var(--secondary-color);
        &:hover {
          box-shadow: unset;
          transform: unset;
        }
      }
      .btn-gradient.login-active {
        color: var(--light-color) !important;
        &::after {
          content: url('../../images/arrow_down_light.svg');
        }
      }
    }
    .dropdown-link {
      &:after {
        content: url('../../images/arrow_down_light.svg');
      }
    }
  }
}
.navbar {
  padding-left: 0;
  padding-right: 0;
  @media (max-width: 991px) {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0;
    height: 100%;
    width: 260px;
  }
  #sociales {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    margin: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s .3s;
    transition: visibility 0s .3s;
  }
  &.nav-is-visible {
    #sociales {
      opacity: 1;
      visibility: visible;
      -webkit-transition: visibility 0s 0s;
      transition: visibility 0s 0s;
    }
  }
}

.navbar-nav {
  flex-direction: row;
  @media (max-width: 991px) {
    flex-direction: column;
    position: relative;
    top: 0;
    right: 0;
    height: 100%;
    width: 260px;
    background-color: var(--heading-color);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 1;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }

}

@media only screen and (max-width: 991px) {
  .navbar-nav {
    visibility: hidden;
    -webkit-transition: visibility 0s 0.3s;
    -moz-transition: visibility 0s 0.3s;
    transition: visibility 0s 0.3s;
  }
  .navbar-nav.nav-is-visible {
    visibility: visible;
    -webkit-transition: visibility 0s 0s;
    -moz-transition: visibility 0s 0s;
    transition: visibility 0s 0s;
  }
}

.nav-item {
  .nav-link:not(.account-link) {
    color: var(--light-color);
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    @media (min-width: 992px) {
      color: var(--text-color);
      padding-right: .5rem;
      padding-left: .5rem;
      &:after {
          content: '';
          background-color: var(--primary-color);
          border-radius: 50px;
          height: 2px;
          width: 80%;
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          transition: all .3s ease-in-out;
          opacity: 0;
          visibility: hidden;
      }
      &:hover {
        &:after {
          bottom: 0;
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &.account-link {
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
    color: var(--light-color);
    text-align: center;
    a:hover {
      color: var(--light-color);
    }
  }
  &.active {
    .nav-link {
      &:after {
        content: '';
        background-color: var(--primary-color);
        border-radius: 50px;
        height: 2px;
        width: 80%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all .3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        opacity: 1;
        visibility: visible;
        @media (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .nav-item {
    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(var(--light-color), .1);
    }
    .nav-link {
      padding: 1em;
      color: var(--light-color);
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

/** DROPDOWN */
.dropdown-link {
  position: relative;
  @media (min-width: 992px) {
    &:after {
      content: url('../../images/arrow_down.svg');
      position: absolute;
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      line-height: 0;
    }
  }
}
@media (min-width: 992px) {
  .dropdown-link {
    margin-right: 10px;
    &:hover {
      .dropdown {
        opacity: 1;
        visibility: visible;
        transform: translateY(15px);
      }
    }
    .nav-link {
      &:hover {
        &:after {
          content: unset;
        }
      }
    }
  }
  .dropdown {
    background-color: var(--light-color);
    border-radius: 5px;
    box-shadow: 0 0 30px 0 rgba(var(--secondary-color-rgb),.1);
    list-style-type: none;
    padding: 15px 10px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
    transition: all .3s ease-in-out;
    width: 200px;
    &:before {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      background-color: var(--light-color);
      top: -5px;
      left: 20px;
      transform: rotate(45deg);
      z-index: 1;
    }
    li {
      &:not(:last-of-type) {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--primary-color-light);
      }
      a {
        color: var(--secondary-color);
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
}

/** LOGIN */
.client-account {
  position: relative;
  @media (max-width: 991px) {
    display: none;
  }
  &:hover {
    .account-dropdown {
      top: 100%;
      pointer-events: auto;
      opacity: 1;
      visibility: visible;
    }
  }
  .account-dropdown {
    text-align: left;
    right: 0;
    top: calc(100% + 15px);
    position: absolute;
    pointer-events: none;
    opacity: 0;
    z-index: 99;
    visibility: hidden;
    color: #000;
    padding-top: 10px;
    transition: all .3s ease-in-out;
  }
  .account-wrapper {
    border-radius: 5px;
    position: relative;
    background: var(--light-color);
    box-shadow: 0 0 30px 0 rgba(var(--secondary-color-rgb), .1);
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      background-color: var(--light-color);
      top: -5px;
      right: 75px;
      transform: rotate(45deg);
      z-index: 1;
    }
  }
  .account-inner {
    width: 320px;
    padding: 17px 20px;
    height: 307px;
    max-height: 500px;
    overflow: auto;
    &__login {
      width: 200px;
      height: auto;
    }
  }
  .login-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid #eaeaea;
    span {
      color: var(--secondary-color);
      font-weight: 600;
      font-size: 1.3em;
    }
    a {
      color: var(--primary-color);
      &:hover {
        color: var(--secondary-color);
      }
    }
  }
  .login-form,
  .forgot-form {
    margin-top: 1.3em;
    .form-group {
      position: relative;
      @extend .solicitud;
      button {
        width: 100%;
      }
    }
  }
  .login-footer {
    a {
      color: var(--primary-color);
      &:hover {
        color: var(--secondary-color);
      }
    }
  }
}
.login-info {
  &__profile, &__close {
    padding: 10px 0;
    &:not(:last-of-type) {
      border-bottom: 1px solid var(--primary-color-light);
      padding-top: 0;
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    a {
      color: var(--secondary-color);
      &:hover {
        color: var(--primary-color);
      }
    }
  }
}

/** MOBILE ACCOUNT */
.mobile-client__account {
  position: relative;
  width: 100%;
  height: auto;
}
.account-header {
  padding: 20px;
  background-color: #082744;
}
.account-header__icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--heading-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  i {
    color: var(--light-color);
    font-size: 1.2em;
  }
}
.account-header__user {
  color: var(--light-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1em;
  .user-name {
    font-size: 1.1em;
  }
  .user-email {
    font-weight: 300;
    font-size: .8em;
  }
}
.account-links {
  background-color: #0b2d4d;
  margin: 0;
  li:not(:last-of-type) {
    border-bottom: 1px solid rgba(var(--light-color), .1);
  }
}
.account-links a {
  color: var(--light-color);
  display: block;
  padding: 1em;
  i {
    margin-right: 10px;
  }
}

/** HERO */
.hero {
  padding: 50px 0 50px 0;
  @media (min-width: 992px) {
    padding: 100px 0 100px 0;
  }
}
.hero-info {
  &__title h1 {
    color: var(--heading-color);
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    @media (min-width: 576px) {
      font-size: 36px;
      line-height: 50px;
    }
  }
  &__text {
    font-size: 1.2em;
    max-width: 100%;
    width: 80%;
  }
}
.hero-image {
  text-align: center;
  img {
    max-width: 100%;
    width: 500px;
    @media (min-width: 992px) {
      width: 700px;
    }
  }
}