/** STAFF */
.staff-filter {
  display: flex;
  flex-direction: column;
  @media (min-width: 991px) {
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }
  &__item {
    &:not(:last-of-type) {
      margin-bottom: 20px;
      @media (min-width: 991px) {
        margin-right: 30px;
        margin-bottom: 0;
      }
    }
    select {
      background-color: var(--light-color);
      border: none;
      color: var(--secondary-color);
      font-weight: 500;
      border-radius: 5px;
      padding: 7px 10px;
      box-shadow: 0 5px 10px -1px rgba(var(--secondary-color-rgb), .2);
      @media (max-width: 992px) {
        max-width: 100%;
        width: 200px;
      }
    }
  }
}

/** ajax loading */
#staff-loading {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
  &::before, &::after {
    content: "";
    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    left: calc(50% - 10px);
    background-color: var(--primary-color);
    animation: loaderMove 1.5s ease-in-out infinite;    
  }
  &::after {
    background-color: var(--secondary-color);
    bottom: 0;
    animation-delay: 0.7s;
  }
}
@keyframes loaderMove {
  0%, 100%{
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
    -o-transform: translate(0,0);
    transform: translate(0,0);
  }

  25%{
    -webkit-transform: translate(20px,20px);
    -ms-transform: translate(20px,20px);
    -o-transform: translate(20px,20px);
    transform: translate(20px,20px);
  }

  50%{
    -webkit-transform: translate(0px,40px);
    -ms-transform: translate(0px,40px);
    -o-transform: translate(0px,40px);
    transform: translate(0px,40px);
  }

  75%{
    -webkit-transform: translate(-20px,20px);
    -ms-transform: translate(-20px,20px);
    -o-transform: translate(-20px,20px);
    transform: translate(-20px,20px);
  }
}

/** not results found */
.no-results {
  color: var(--secondary-color);
  font-size: 1.5em;
  position: absolute;
  &:after {
    content: url('../../images/not-results.svg');
    position: absolute;
    top: 50%;
    right: -50px;
    width: 40px;
    height: 40px;
    transform: translateY(-50%);
  }
}

.staff-wrapper {
  margin-top: 50px;
  @media (min-width: 991px) {
    margin-top: 80px;
  }
}
.staff-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
	list-style: none;
	padding: 20px 0;
  width: 100%;
  @media (min-width: 576px) {
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

.staff-grid li {
  animation: appear .5s ease-in-out 0s 1 forwards;
  opacity: 0;
  @for $i from 1 to 30 {
      &:nth-child(#{$i}) { 
          animation-delay: $i * 0.1s;
      }
  }
}

@keyframes appear {
  from {
      opacity: 0;
      transform: translateY(20px)
  }
  to {
      opacity: 1;
      transform: translateY(0)
  }
}
@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.staff-grid li > a,
.staff-grid li > a img {
	border: none;
	outline: none;
	display: block;
	position: relative;
}

.staff-grid li.staff-expanded > a::after {
	top: auto;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-bottom-color: var(--primary-color-light);
	border-width: 15px;
	left: 50%;
	margin: -20px 0 0 -15px;
}

.staff-content {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  visibility: hidden;
  background-color: var(--primary-color-light);
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: -webkit-transform 0.4s 0s, visibility 0s 0.4s;
  -moz-transition: -moz-transform 0.4s 0s, visibility 0s 0.4s;
  transition: transform 0.4s 0s, visibility 0s 0.4s;
  -webkit-transition-timing-function: cubic-bezier(0.67, 0.15, 0.83, 0.83);
  -moz-transition-timing-function: cubic-bezier(0.67, 0.15, 0.83, 0.83);
  transition-timing-function: cubic-bezier(0.67, 0.15, 0.83, 0.83);
  &.is-visible {
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: -webkit-transform 0.4s 0s, visibility 0s 0s;
    -moz-transition: -moz-transform 0.4s 0s, visibility 0s 0s;
    transition: transform 0.4s 0s, visibility 0s 0s;
    -webkit-transition-timing-function: cubic-bezier(0.67, 0.15, 0.83, 0.83);
    -moz-transition-timing-function: cubic-bezier(0.67, 0.15, 0.83, 0.83);
    transition-timing-function: cubic-bezier(0.67, 0.15, 0.83, 0.83);
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondary-color);
    border: 0px none var(--light-color);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-color);
  }
  &::-webkit-scrollbar-thumb:active {
    background: var(--secondary-color);
  }
  &::-webkit-scrollbar-track {
    background: #f8dcf0;
    border: 0px none var(--light-color);
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track:hover {
    background: #ffb5ea;
  }
  &::-webkit-scrollbar-track:active {
    background: #ffb5ea;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.staff-content-inner {
	padding: 50px 30px;
	height: 100%;
}

.staff-close {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.staff-close::before,
.staff-close::after {
	content: '';
	position: absolute;
	width: 100%;
	top: 50%;
	height: 1px;
	background: var(--primary-color);
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	transform: rotate(45deg);
}

.staff-close::after {
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.staff-close:hover::before,
.staff-close:hover::after {
	background: var(--secondary-color);
}

.staff-fullimg,
.staff-details {
	width: 50%;
	float: left;
	height: 100%;
	// overflow-y: auto;
	position: relative;
}

.staff-details {
  @media (min-width: 767px) {
    padding: 0 40px 0 20px;
  }
}

.staff-fullimg {
  text-align: center;
  @media (max-width: 992px) {
    height: auto !important;
  }
}

.staff-fullimg img {
  border-radius: 5px;
	display: inline-block;
	max-height: 100%;
	max-width: 100%;
}

.staff-details h3 {
  color: var(--secondary-color);
	font-weight: 500;
	font-size: 30px;
	padding: 40px 0 10px;
  margin-bottom: 10px;
  @media (min-width: 991px) {
    font-size: 42px;
  }
}

.staff-details p {
  color: var(--secondary-color);
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
}

.staff-details a {
  color: var(--secondary-color);
  text-decoration: underline;
  &:hover {
    color: var(--primary-color);
  }
}

.staff-info {
  margin-bottom: 20px;
  &__item {
    color: var(--secondary-color);
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
    padding-left: 35px;
    position: relative;
    @media (min-width: 450px) {
      display: inline-block;
      margin-bottom: 0;
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      color: var(--primary-color);
    }
    &.nacionalidad {
      &:after {
        content: url(../../images/icon_country.svg);
      }
    }
    &.residencia {
      &:after {
        content: url(../../images/icon_residence.svg);
      }
    }
    &.edad {
      &:after {
        content: url(../../images/icon_age.svg);
      }
    }
    &.celular {
      &:after {
        content: url(../../images/icon_phone.svg);
      }
    }
    &.referencias {
      background-color: #f6c7e8;
      border-radius: 5px;
      border: 1px solid rgba(var(--secondary-color-rgb), .2);
      margin-top: 15px;
      padding: 10px;
      width: 100%;
      p {
        color: var(--primary-color);
        display: block;
        font-weight: 500;
        margin-bottom: 5px;
      }
    }
  }
}

.staff-disposicion {
  margin-bottom: 40px;
  display: block;
  width: 100%;
  &__item {
    color: var(--secondary-color);
    font-weight: 500;
    display: flex;
    align-items: center;
    position: relative;
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
    &:before {
      content: '';
      display: flex;
      justify-content: center;
      margin-right: 20px;
      width: 40px;
      height: 40px;
      background-color: var(--primary-color-light);
      border: 2px solid var(--light-color);
      border-radius: 50%;
      box-shadow: 0 1px 3px rgba(var(--secondary-color-rgb), .1);
      text-align: center;
      line-height: 45px;
    }
    &#adentro {
      &:before {
        content: url('../../images/icon_padentro.svg');
      }
    }
    &#afuera {
      &:before {
        content: url('../../images/icon_pafuera.svg');
      }
    }
    &#partTime {
      &:before {
        content: url('../../images/icon_parttime.svg');
      }
    }
  }
}

@media (max-width: 992px) {
	.staff-details { width: 100%; }
}

.staff-item {
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(var(--secondary-color-rgb), .1);
  padding: 10px;
  transition: all .3s ease-in-out;
  
  &:hover {
    box-shadow: 0 10px 30px rgba(var(--secondary-color-rgb), .3);
  }

  &__header {
    position: relative;
    width: 100%;
    .image-wrapper {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      height: 250px;
      @media (min-width: 767px) {
        height: 200px;
      }
      img {
        border-radius: 5px;
        object-fit: cover;
        object-position: center 20%;
        height: 250px;
        width: 100%;
        @media (min-width: 767px) {
          height: 200px;
        }
      }
    }
    .icons {
      display: flex;
      position: absolute;
      bottom: -20px;
      right: 15px;
      .icon {
        background-color: var(--primary-color-light);
        border: 2px solid var(--light-color);
        border-radius: 50%;
        box-shadow: 0 1px 3px rgba(var(--secondary-color-rgb), .1);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        &:not(:last-of-type) {
          margin-right: 5px;
        }
        img {
          width: 60%;
        }
      }
    }
  }
  &__content {
    margin-top: 20px;
    padding: 15px;
    .main-info {
      margin-bottom: 20px;
      .info {
        color: var(--secondary-color);
        font-size: 1.1em;
      }
    }
    .description {
      p {
        color: var(--text-color);
        margin-bottom: 0;
      }
    }
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  width: 100%;
  .pagination {
    justify-content: center;
    width: 100%;
  }
  .pagination ul {
    border-radius: 30px;
    box-shadow: 0 5px 10px rgba(var(--secondary-color-rgb), .1);
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 450px;
    max-width: 100%;
    padding: 5px 0;
    .prev,
    .next {
      background-color: unset;
      border: none;
      color: unset;
      line-height: unset;
      padding: 0;
      transition: all .3s ease-in-out;
    }
    .prev { 
      margin-right: 15px; 
      &:hover {
        transform: translateX(-5px);
      }
    }
    .next { 
      margin-left: 15px; 
      &:hover {
        transform: translateX(5px);
      }
    }
  }
}

.pagination ul li {
  display: flex;
  .page-link:not(.next):not(.prev), 
  .current:not(.next):not(.prev) {
    border-radius: 50%;
    color: var(--text-color);
    font-weight: 500;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
    &.active,
    &:hover {
      background-color: var(--primary-color-light);
      color: var(--secondary-color);
      font-weight: 700;
    }
  }
  &:not(:last-of-type) {
    margin-right: 10px;
  }
  .current:not(.prev):not(.next) {
    background-color: var(--primary-color-light);
    color: var(--secondary-color);
    font-weight: 700;
  }
}