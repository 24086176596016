.range-filter {
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 991px) {
    align-items: center;
    flex-direction: row;
  } 
  p {
    color: var(--secondary-color);
    font-weight: 500;
    margin-bottom: 20px;
    @media (min-width: 991px) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}
#age_show {
  position: absolute;
  top: 0;
  left: 50px;
  margin: 0;
  @media (min-width: 991px) {
    top: -25px;
  }
}
#age_range {
  width: 150px;
}
.ui-slider-range {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  border-radius: 10px;
  border: none !important;
}
.ui-slider-handle {
  background-color: var(--secondary-color) !important;
  border: none !important;
  border-radius: 50%;
  &:focus {
    outline: none;
  }
}
.ranges {
  width: 200px;
  height: 5px;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  border-radius: 10px;
  position: relative;
  @media (min-width: 991px) {
    width: 130px;
  }

  .range-wrapper {
    .range-tooltip {
      opacity: 0;
      visibility: hidden;
      color: var(--primary-color);
      position: absolute;
      top: -40px;
      width: 25px;
      height: 25px;
      background-color: var(--primary-color-light);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .8em;
      font-weight: 700;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
  }

  input[type="range"] {
    appearance: none;
    pointer-events: none;
    position: absolute;
    border: none;
    border-radius: 5px;
    overflow: hidden;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 200px;
    outline: none;
    margin: 0;
    padding: 0;
    background: transparent;
    @media (min-width: 991px) {
      width: 130px;
    }
    &::-webkit-slider-thumb {
      pointer-events: all;
      position: relative;
      z-index: 1;
      outline: 0;
      -webkit-appearance: none;
      width: 25px;
      height: 25px;
      border: none;
      border-radius: 50%;
      background-color: var(--secondary-color);
      @media (min-width: 991px) {
        width: 15px;
        height: 15px;
      }
    }
    &::-moz-range-thumb {
      pointer-events: all;
      position: relative;
      z-index: 10;
      -moz-appearance: none;
      width: 25px;
      height: 25px;
      border: none;
      border-radius: 50%;
      background-color: var(--secondary-color);
      @media (min-width: 991px) {
        width: 15px;
        height: 15px;
      }
    }
    &::-ms-thumb {
      pointer-events: all;
      position: relative;
      z-index: 10;
      -ms-appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 0;
      background-color: var(--secondary-color);
      @media (min-width: 991px) {
        width: 15px;
        height: 15px;
      }
    }
    &::-moz-range-track {
      position: relative;
      z-index: -1;
      background-color: rgba(0, 0, 0, 1);
      border: 0;
    }
    &:last-of-type::-moz-range-track {
      -moz-appearance: none;
      background: none transparent;
      border: 0;
    }
    &::-moz-focus-outer {
      border: 0;
    }
  }

}