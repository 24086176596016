.nm-footer {
  background-color: var(--light-color);
  border-top: 1px solid var(--primary-color-light);
  padding-top: 70px;
  img {
    width: 100px;
  }
}

.footer-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 767px) {
      flex-direction: row;
    }
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
    img {
      margin-bottom: 1em;
      width: 20px;
      @media (min-width: 767px) {
        margin-bottom: 0;
        margin-right: 1em;
      }
    }
    a {
      color: var(--text-color);
      font-size: 1.1em;
      &:not(:last-of-type) {
        margin-bottom: .4em;
        @media (min-width: 767px) {
          margin-bottom: 0;
          margin-right: 1em;
        }
      }
      &:hover {
        color: var(--primary-color);
      }
    }
  }
  .sociales {
    margin-left: 0;
  }
}

.footer-bottom {
  border-top: 1px solid #D1D1D1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0;
  @media (min-width: 767px) {
    flex-direction: row;
  }

  &__left {
    a {
      color: var(--secondary-color);
      font-weight: 600;
      &:hover {
        color: var(--primary-color);
      }
    }
  }

  &__right {
    a {
      color: var(--text-color);
      &:hover {
        color: var(--primary-color);
      }
    }
  }

}